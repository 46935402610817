import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Form from "../assent/Submit.png";
const Submit = () => {
  return (
    <>
      <Paper elevation={3} sx={{ borderRadius: "5px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Container maxWidth="md">
            <Grid container spacing={1}>
              <img
                src={Form}
                alt="img"
                style={{ width: "25%", display: "flex", margin: "0 auto" }}
              />
            </Grid>
            <Grid xs={12} sx={{ textAlign: "center" }}></Grid>
            <Grid container spacing={1}>
              <Grid xs={12} sx={{ textAlign: "center" }}>
                <Typography sx={{ fontWeight: "700" }} variant="h3">
                  Thank You
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ mt: 1, fontFamily: "Dancing Script" }}
                >
                  For Submitting Your Application
                </Typography>
                <Typography sx={{ mt: 3, fontFamily: "cursive" }} variant="h5">
                  We will get in touch with you shortly.....
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Paper>
    </>
  );
};

export default Submit;
