// import { Typography } from "@mui/material";
// import React, { useState } from "react";

// const UserDetails = () => {
//   const [data, setData] = useState();

//   React.useEffect(() => {
//     try {
//       fetch("https://careers.ssbi.in/api/viewForms")
//         .then((response) => response.json())
//         .then((data) => console.log(data));
//     } catch (error) {
//       console.log(error);
//     }
//   }, []);

// const data = {
//   id: 2,
//   name: "Saurabh 2 DEMO",
//   contact: "37",
//   email: "tozucigil@mailinator.com",
//   currentAddress: "Cupiditate autem qui",
//   permanentAddress: "Veniam aliquid tota",
//   photoPath: "/home/ubuntu/uploadDoc/thankyou-mob.png",
//   resumePath: "/home/ubuntu/uploadDoc/pic.jpg",
//   fresher: "yes",
//   currentCompanyName: null,
//   currentSalary: null,
//   expectedSalary: null,
//   currentDepartmentOfWork: null,
//   noticePeriod: null,
//   currentRoleResponsebility: null,
//   experience: null,
//   projectInternship: "Adrienne Tyson",
//   itSkills: "Enim voluptates veli",
//   nameOfInstitute: "Venus Emerson",
//   addressOfInstitute: "Consequatur id vol",
//   collegePersonName: "Ifeoma Blankenship",
//   collegeContactPersonNumber: "333",
//   highestEducationQualifications: "pg,graduation,10th",
//   projectLinks: "Placeat esse minim",
//   githubLinkdin: "Omnis rerum cum cons",
//   gender: "Other",
//   maritalStatus: "",
//   religion: "Ratione officiis del",
//   differentlyAbled: "",
//   careerBreak: "1978",
//   openToRelocate: "",
//   englishSpeak: "Yes",
//   englishRead: "",
//   englishWrite: "Yes",
//   hindiSpeak: "",
//   hindiRead: "",
//   hindiWrite: "",
//   otherLanguage: "Nemo voluptatem itaq",
//   otherSpeak: "Yes",
//   otherRead: "Yes",
//   otherWrite: "Yes",
// };

// const fields = [
//   // { label: "Customer Name", key: "customerName" },
//   { label: "Name", key: "name" },
//   { label: "CIF Number", key: "cifNo" },
//   { label: "Customer Id", key: "customerId" },
//   { label: "KYC Acceptance", key: "kycAcceptance" },
//   { label: "Father / Spouse Name", key: "fatherSpouseName" },
//   { label: "Mother Name", key: "motherName" },
//   { label: "Gender", key: "gender" },
//   { label: "Dob", key: "dob" },
//   { label: "Nationality", key: "nationality" },
//   { label: "Marital Status", key: "maritalStatus" },
//   { label: "Caste Category", key: "casteCategory" },
//   { label: "Residential Status", key: "residentialStatus" },
//   { label: "Occupation", key: "occupation" },
//   { label: "Membership Type", key: "membershipType" },
//   { label: "Membership No", key: "membershipNo" },
//   { label: "Pan No", key: "panNo" },
//   { label: "Aadhar Number", key: "aadharNumber" },
//   { label: "Maiden Name", key: "maidenName" },
//   // { label: "Pan Number", key: "panNumber" },
//   // { label: "Uid", key: "uid" },
//   { label: "VoterId Card", key: "voterIdCard" },
//   { label: "Nrega Job Card", key: "nregaJobCard" },
//   { label: "Passport Number", key: "passportNumber" },
//   { label: "Passport Expiry Date", key: "passportExpiryDate" },
//   { label: "Driving License", key: "drivingLicense" },
//   { label: "Driving License Expiry Date", key: "drivingLicenseExpiryDate" },
//   { label: "Other Doc No", key: "otherDocNo" },
//   { label: "POA", key: "poa" },
//   { label: "Other POA", key: "otherPoa" },
//   { label: "Line1", key: "line1" },
//   { label: "Line2", key: "line2" },
//   { label: "State", key: "state" },
//   { label: "District", key: "district" },
//   { label: "Taluka", key: "taluka" },
//   { label: "Village", key: "village" },
//   { label: "Pin Post Code", key: "pinPostCode" },
//   { label: "Country", key: "country" },
//   { label: "Line1 (Correspondence)", key: "line12" },
//   { label: "Line2 (Correspondence)", key: "line22" },
//   { label: "State (Correspondence)", key: "state2" },
//   { label: "District (Correspondence)", key: "district2" },
//   { label: "Taluka (Correspondence)", key: "taluka2" },
//   { label: "Village (Correspondence)", key: "village2" },
//   { label: "Pin Post Code (Correspondence)", key: "pinPostCode2" },
//   { label: "Country (Correspondence)", key: "country2" },
//   { label: "Tel Off", key: "telOff" },
//   { label: "Tel Res", key: "telRes" },
//   { label: "Mobile", key: "mobile" },
//   { label: "Fax", key: "fax" },
//   { label: "Email Id", key: "emailId" },
// ];

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";

const ViewForm = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    try {
      axios
        .get(`https://careers.ssbi.in/api/viewForm/${id}`, {
          "Content-Type": "application/json",
        })
        .then((res) => setData(res.data));
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  // const componentRef = useRef();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Data",
    pageStyle: `
      @page {
        size: portrait;
      }      
    `,
  });

  const fields = [
    { label: "Id", key: "id" },
    { label: "Name", key: "name" },
    { label: "Contact", key: "contact" },
    { label: "Email Id", key: "email" },
    { label: "Current Address", key: "currentAddress" },
    { label: "Permanent Address", key: "permanentAddress" },
    { label: "Fresher", key: "fresher" },
    { label: "experience", key: "experience" },
    { label: "IT Skills", key: "itSkills" },
    { label: "Current Company Name", key: "currentCompanyName" },
    { label: "Current Salary", key: "currentSalary" },
    { label: "Expected Salary", key: "expectedSalary" },
    { label: "Current Department Of Work", key: "currentDepartmentOfWork" },
    { label: "Notice Period", key: "noticePeriod" },
    { label: "Current Role Responsebility", key: "currentRoleResponsebility" },    
    { label: "Project Internship", key: "projectInternship" },    
    { label: "Project Links", key: "projectLinks" },
    { label: "Github Linkdin", key: "githubLinkdin" },
    { label: "Name Of Institute", key: "nameOfInstitute" },
    { label: "Address Of Institute", key: "addressOfInstitute" },
    { label: "College Person Name", key: "collegePersonName" },
    { label: "College Contact Person Number", key: "collegeContactPersonNumber" },
    { label: "EducationQualifications", key: "highestEducationQualifications" },    
    { label: "Gender", key: "gender" },
    { label: "Marital Status", key: "maritalStatus" },
    { label: "Religion", key: "religion" },
    { label: "DifferentlyAbled", key: "differentlyAbled" },
    { label: "careerBreak", key: "careerBreak" },
    { label: "Open To Relocate", key: "openToRelocate" },
    { label: "English Speak", key: "englishSpeak" },
    { label: "English Read", key: "englishRead" },
    { label: "English Write", key: "englishWrite" },
    { label: "Hindi Speak", key: "hindiSpeak" },
    { label: "Hindi Read", key: "hindiRead" },
    { label: "Hindi Writes", key: "hindiWrite" },
    { label: "Any Other Language Known", key: "otherLanguage" }, 





  ];

  return (
    <>
      <Box marginBottom={"5vh"}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
             <Button
          onClick={handlePrint}
          sx={{ height: 50, width: 200 }}
          variant="contained"
          color="info"
        >
          Print / Download
        </Button>
          </div>
          <Box 
          // ref={componentRef}
          >
            <Typography variant="h4" style={{ color: "purple" }}>
              Candidate Details
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {fields.map((field, index) => (
                    <TableRow
                      key={field.key}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <TableCell>{field.label}</TableCell>
                      <TableCell>
                        <span style={{ color: "red", fontSize: "18px" }}>
                          {data[field.key]}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </Box>
      <Box display="none">
        <Box 
          ref={componentRef}
          >
            <Typography variant="h4" style={{ color: "purple" }}>
              Candidate Details
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {fields.map((field, index) => (
                    <TableRow
                      key={field.key}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <TableCell>{field.label}</TableCell>
                      <TableCell>
                        <span style={{ color: "red", fontSize: "18px" }}>
                          {data[field.key]}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

      </Box>
    </>
  );
};

export default ViewForm;
