import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Home from "../Pages/Home";
import Submit from "../Pages/Submit";
import Admin from "../Pages/admin";
// import UserDetail from "../Pages/viewForm"
import Logo from "../assent/logo.png";
import { Link, Route, Routes } from "react-router-dom";
import { Typography } from "@mui/material";
// import viewForm from "../Pages/viewForm";
import ViewForm from "../Pages/viewForm";

export default function Navbar() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: "linear-gradient(to right, #3498db, #e74c3c)",
        }}
      >
        <Toolbar>
          <img src={Logo} alt="img" style={{ width: "10%" }} />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              margin: "auto",
              fontWeight: "700",
              fontSize: "30px",
              fontFamily: "system-ui",
            }}
          >
            Job Application Form
          </Typography>
          {/* <Link to="/admin" style={{ textDecoration: "none", color: "#FFF" }}>
            Admin Login
          </Link> */}
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/submitted" element={<Submit />} />
          <Route path="/admin" element={<Admin />} />
          {/* <Route path="/userDetail" element={<UserDetail />} /> */}
          <Route path="/viewForm/:id" element={<ViewForm />} />
        </Routes>
      </Box>
      <Box
        component="footer"
        sx={{
          background: "linear-gradient(to right, #e74c3c, #3498db, #e74c3c)",
          color: "white",
          textAlign: "left",
          // marginLeft: "12.5vw",
          padding: "4px",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        Copyright © 2023 SSBI. All rights reserved.
      </Box>
    </Box>
  );
}
