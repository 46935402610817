import {
  Box,
  Paper,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
// import TextField from '@mui/material/TextField';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Home = () => {
  const [value, setValue] = React.useState("female");
  const [val, setVal] = useState([]);
  const [vl, setVl] = useState([]);
  const [lan, setLan] = useState([]);
  const [file, setFile] = useState("");
  const [fileResume, setFileResume] = useState("");
  const [filePhoto, setFilePhoto] = useState("");

  const navigate = useNavigate();
  // const [resume, setResume] = useState(null);
  // const [photo, setPhoto] = useState(null);

  const years = [
    { label: "1+ Years" },
    { label: "2+ Years" },
    { label: "5+ Years" },
    { label: "10+ Years" },
  ];
  const marital = [{ label: "Married" }, { label: "Unmarried" }];
  const differentlyAbled = [{ label: "Yes" }, { label: "No" }];
  const openToRelocate = [{ label: "Yes" }, { label: "No" }];
  const [apply, setAply] = useState({
    nameOfInstitute: "",
    addressOfInstitute: "",
    collegePersonName: "",
    collegeContactPersonNumber: "",
    highestEducationQualifications: "",
    projectLinks: "",
    githubLinkdin: "",
    permanentAddress: "",
    photoPath: "",
    projectInternship: "",
    resumePath: "",
    gender: "",
    maritalStatus: "",
    religion: "",
    differentlyAbled: "",
    careerBreak: "",
    openToRelocate: "",
    englishSpeak: "",
    englishRead: "",
    englishWrite: "",
    hindiSpeak: "",
    hindiRead: "",
    hindiWrite: "",
    otherLanguage: "",
    otherSpeak: "",
    otherRead: "",
    otherWrite: "",
  });
  const [records, setRecords] = useState([]);
  const handleChange = (event) => {
    setValue(event.target.value);
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value);
    setAply({ ...apply, [name]: value });
  };

  const handleAdd = () => {
    const abc = [...val, []];
    setVal(abc);
  };
  const handleChange2 = (onChangeValue, i) => {
    const TextFielddata = [...val];
    TextFielddata[i] = onChangeValue.target.value;
    setVal(TextFielddata);
    const name = onChangeValue.target.name;
    const value = onChangeValue.target.value;
    console.log(name, value);
    setAply({ ...apply, [name]: value });
  };
  const handleDelete = (i) => {
    const deletVal = [...val];
    deletVal.splice(i, 1);
    setVal(deletVal);
  };
  // ---------------------------------- //
  const autocomp = () => {
    const abc = [...vl, []];
    setVl(abc);
  };
  const langCheack = (e) => {
    setAply({ ...apply, [e.target.name]: e.target.checked ? "Yes" : "NO" });
  };
  const uplodFileResume = (e) => {
    setFileResume(e.target.files[0]);
    setAply({ ...apply, resumePath: e.target.files[0].name });
  };
  const uplodFilePhoto = (e) => {
    setFilePhoto(e.target.files[0]);
    setAply({ ...apply, photoPath: e.target.files[0].name });
  };
  const [resume, setResume] = useState(null);
  const [photo, setPhoto] = useState(null);

  const handleResumeChange = (e) => {
    setResume(e.target.files[0]);
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };
  // const uplodFiles = () => {
  //   const upload = [...files, file];
  //   setFiles(upload);
  //   console.log(upload);
  // };
  const handleApply = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAply({ ...apply, [name]: value });
  };
  const handleDegree = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    let newArr = apply.highestEducationQualifications
      ? [...apply.highestEducationQualifications.split(",")]
      : [];
    if (value) {
      newArr.push(name);
    } else {
      newArr = newArr.filter((degree) => name !== degree);
    }
    setAply({ ...apply, highestEducationQualifications: newArr.toString() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    const jsonData = JSON.stringify(apply);
    form.append("form", jsonData); // Change content type to "application/json"
    form.append("resume", fileResume);
    form.append("photo", filePhoto);

    try {
      const res = await axios.post('https://careers.ssbi.in/api/submitForm', form);
      navigate("/submitted");
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <Paper
        elevation={3}
        sx={{ marginBottom: "10px", padding: "20px", borderRadius: "25px" }}
      > */}
      <Paper
        elevation={3}
        sx={{
          marginBottom: "20px",
          marginLeft: "10px",
          padding: "20px",
          borderRadius: "25px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 1)", // Add a subtle shadow
        }}
      >
        <Box>
          <Box>
            <Typography sx={{ fontWeight: "700" }} variant="h6">
              Basic Details of Candidate
            </Typography>
          </Box>
          <TextField
            label="Name"
            helperText="Enter Your Full Name"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="name"
            value={apply.name}
            onChange={handleApply}
            type="name"
          />
          <TextField
            label="Contact Number"
            helperText="Enter 10-digit number"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="contact"
            onChange={handleApply}
            type="number"
          />
          <TextField
            label="Email"
            helperText="Enter Valid Email Id"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="email"
            onChange={handleApply}
            type="email"
          />
          <FormHelperText sx={{ color: "red", marginLeft: 2 }}>
            Note: Photo and Resume are Mandatory to Upload.
          </FormHelperText>

          <TextField
            helperText="Photo (.jpg format only)"
            // label="Photo in (.jpg) format"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            onChange={uplodFilePhoto}
            name="photoPath"
            type="file"
            inputProps={{ accept: "image/*" }}
          />

          <TextField
            helperText="Resume (.pdf format only)"
            // label="Resume in (.pdf) format"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="resumePath"
            onChange={uplodFileResume}
            type="file"
            inputProps={{ accept: "application/pdf" }}
          />

          <TextField
            label="Current Address"
            helperText="Enter Valid Address"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="currentAddress"
            onChange={handleApply}
            type="name"
          />
          <TextField
            label="Permanent Address"
            helperText="Enter Valid Address"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="permanentAddress"
            onChange={handleApply}
            type="name"
          />

          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Fresher
            </FormLabel>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row-reverse",
              }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
                name="fresher"
              >
                <FormControlLabel
                  // name="yes"
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  // name="no"
                  value="no"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </div>
          </FormControl>
          {/* {value === "uplod" ? (
            <TableContainer sx={{ mt: 3, borderRadius: 2, border: 1.5 }}>
              <Table size="small" sx={{ minWidth: 400 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Sr. No.</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">View Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </TableContainer>
          ) : null} */}
          {value === "no" ? (
            <Box>
              <TextField
                label="Current Companay Name"
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                name="currentCompanyName"
                onChange={handleApply}
                type="name"
              />
              <TextField
                label="Current Salary (In Rupees)"
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                name="currentSalary"
                onChange={handleApply}
                type="name"
              />
              <TextField
                label="Expected Salary (In Rupees)"
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                name="expectedSalary"
                onChange={handleApply}
                type="name"
              />
              <TextField
                label="Current Department of Work"
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                name="currentDepartmentOfWork"
                onChange={handleApply}
                type="name"
              />
              <TextField
                label="Notice Period (In Months)"
                helperText="Example: 2 Months"
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                name="noticePeriod"
                onChange={handleApply}
                type="name"
              />
              <TextField
                label="Current Role & Responsebility"
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                name="currentRoleResponsebility"
                onChange={handleApply}
                type="name"
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="experience"
                onChange={(e, value) => {
                  setAply({ ...apply, experience: value.label });
                }}
                options={years}
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Experience"
                    helperText="Please Choose From The Mentioned Options"
                  />
                )}
              />
            </Box>
          ) : null}
        </Box>
        <Box>
          <Box>
            <Typography sx={{ fontWeight: "700" }} variant="h6">
              Profile Summary
            </Typography>
          </Box>
          <TextField
            label="Projects & Internship Name"
            helperText="Use Comma if you have done more than one"
            sx={{ m: 1, width: { xs: "96%", sm: "92%" } }}
            name="projectInternship"
            type="name"
            onChange={handleApply}
          />
          <TextField
            label="IT Skills (Use Comma if you have more than one skills)"
            helperText="Example: JAVA, Python, C, C++, Postman, MySQL, etc..."
            sx={{ m: 1, width: { xs: "96%", sm: "92%" } }}
            name="itSkills"
            onChange={handleApply}
            type="name"
          />
        </Box>
        <Box>
          <Box>
            <Typography sx={{ fontWeight: "700" }} variant="h6">
              Institute Details
            </Typography>
          </Box>
          <TextField
            label="Name of Institute"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="nameOfInstitute"
            type="name"
            onChange={handleApply}
          />
          <TextField
            label="Address of Institute"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="addressOfInstitute"
            type="name"
            onChange={handleApply}
          />
          <TextField
            label="College Contact Person Name"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="collegePersonName"
            type="name"
            onChange={handleApply}
          />
          <TextField
            label="College Contact Person Number"
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="collegeContactPersonNumber"
            onChange={handleApply}
            helperText="Enter 10-digit number"
            type="number"
          />
        </Box>
        <Box>
          <Box>
            <Typography sx={{ fontWeight: "700" }} variant="h6">
              Educational Qualification
            </Typography>
            {/* <AddIcon
              sx={{
                ml: 1,
                background: "#1976d2",
                color: "#fff",
                width: "3%",
                height: "30px",
                borderRadius: "50%",
              }}
              onClick={() => handleAdd2()}
            /> */}
          </Box>
          <FormGroup sx={{ ml: 3 }}>
            <FormControlLabel
              sx={{ width: "10%" }}
              control={<Checkbox />}
              onChange={handleDegree}
              name="pg"
              label="PG/M.Tech/MBA"
            />
            <FormControlLabel
              sx={{ width: "10%" }}
              control={<Checkbox />}
              label="Graduation/B.E/B.Tech"
              name="graduation"
              onChange={handleDegree}
            />
            <FormControlLabel
              sx={{ width: "10%" }}
              control={<Checkbox />}
              label="ITI"
              name="iti"
              onChange={handleDegree}
            />
            <FormControlLabel
              sx={{ width: "10%" }}
              control={<Checkbox />}
              label="Diploma"
              name="diploma"
              onChange={handleDegree}
            />
            <FormControlLabel
              sx={{ width: "10%" }}
              control={<Checkbox />}
              label="12th"
              name="12th"
              onChange={handleDegree}
            />
            <FormControlLabel
              sx={{ width: "10%" }}
              control={<Checkbox />}
              label="10th"
              name="10th"
              onChange={handleDegree}
            />
            <FormControlLabel
              sx={{ width: "10%" }}
              control={<Checkbox />}
              label="Others"
              name="others"
              onChange={handleDegree}
            />
          </FormGroup>
        </Box>
        <Box>
          {vl.map((data, i) => {
            return (
              <>
                <TextField
                  label=""
                  sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                  name="add"
                  onChange={handleApply}
                  type="name"
                />
              </>
            );
          })}
        </Box>
        <Box>
          <Typography sx={{ fontWeight: "700" }} variant="h6">
            Online Link
          </Typography>
          <TextField
            label="Project Links (Use Comma if you have more than one links)"
            helperText="Example: www.abc.in, www.xyx.co.in, etc..."
            sx={{ m: 1, width: { xs: "96%", sm: "62%" } }}
            name="projectLinks"
            onChange={handleApply}
            type="name"
          />
          <TextField
            label="Github/Linkdin/Other"
            helperText="Example: github.com/username, linkedin.com/abc, etc..."
            sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
            name="githubLinkdin"
            onChange={handleApply}
            type="name"
          />
        </Box>
        <Box>
          <Typography sx={{ fontWeight: "700" }} variant="h6">
            Personal Details
          </Typography>
          <Box>
            <FormControl sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
              <RadioGroup row name="gender" onChange={handleApply}>
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label="Female"
                  name="gender"
                />
                <FormControlLabel
                  value="Male"
                  name="gender"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="Other"
                  name="gender"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="maritalStatus"
                onChange={(e, value) => {
                  setAply({ ...apply, maritalStatus: value.label });
                }}
                options={marital}
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Marital Status"
                    helperText="Please Choose From The Mentioned Options"
                  />
                )}
              />
              <TextField
                label="Religion"
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                name="religion"
                onChange={handleApply}
                type="name"
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="differentlyAbled"
                onChange={(e, value) => {
                  setAply({ ...apply, differentlyAbled: value.label });
                }}
                options={differentlyAbled}
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Differently Abled"
                    helperText="Please Choose From The Mentioned Options"
                  />
                )}
              />
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <TextField
                label="Career Break (In Years)"
                helperText="Example: 2015-2017"
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                name="careerBreak"
                onChange={handleApply}
                type="name"
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="openToRelocate"
                onChange={(e, value) => {
                  setAply({ ...apply, openToRelocate: value.label });
                }}
                options={openToRelocate}
                sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Open to Relocate"
                    helperText="Please Choose From The Mentioned Options"
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{ m: 1, display: "flex", width: { xs: "96%", sm: "30%" } }}
            >
              <Typography sx={{ fontWeight: "700" }} variant="h6">
                Languages
              </Typography>
            </Box>
            <Box
              sx={{ m: 1, display: "flex", width: { xs: "96%", sm: "30%" } }}
            >
              <Typography sx={{ fontWeight: "700" }} variant="h6">
                Speak
              </Typography>
            </Box>
            <Box
              sx={{ m: 1, display: "flex", width: { xs: "96%", sm: "30%" } }}
            >
              <Typography sx={{ fontWeight: "700" }} variant="h6">
                Read
              </Typography>
            </Box>
            <Box
              sx={{ m: 1, display: "flex", width: { xs: "96%", sm: "30%" } }}
            >
              <Typography sx={{ fontWeight: "700" }} variant="h6">
                Write
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
              variant="h6"
            >
              English
            </Typography>
            <Box sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormControlLabel
                sx={{ width: "10%" }}
                control={<Checkbox />}
                onChange={langCheack}
                name="englishSpeak"
                label=""
              />
            </Box>
            <Box sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormControlLabel
                sx={{ width: "10%" }}
                control={<Checkbox />}
                onChange={langCheack}
                name="englishRead"
                label=""
              />
            </Box>
            <Box sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormControlLabel
                sx={{ width: "10%" }}
                control={<Checkbox />}
                onChange={langCheack}
                name="englishWrite"
                label=""
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
              variant="h6"
            >
              Hindi
            </Typography>
            <Box sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormControlLabel
                sx={{ width: "10%" }}
                control={<Checkbox />}
                onChange={langCheack}
                name="hindiSpeak"
                label=""
              />
            </Box>
            <Box sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormControlLabel
                sx={{ width: "10%" }}
                control={<Checkbox />}
                onChange={langCheack}
                name="hindiRead"
                label=""
              />
            </Box>
            <Box sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormControlLabel
                sx={{ width: "10%" }}
                control={<Checkbox />}
                onChange={langCheack}
                name="hindiWrite"
                label=""
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <TextField
              label="Other Language"
              sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}
              name="otherLanguage"
              onChange={(e) => {
                setAply({ ...apply, otherLanguage: e.target.value });
              }}
              size="small"
              type="name"
            />
            <Box sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormControlLabel
                sx={{ width: "10%" }}
                control={<Checkbox />}
                onChange={langCheack}
                name="otherSpeak"
                label=""
              />
            </Box>
            <Box sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormControlLabel
                sx={{ width: "10%" }}
                control={<Checkbox />}
                onChange={langCheack}
                name="otherRead"
                label=""
              />
            </Box>
            <Box sx={{ m: 1, width: { xs: "96%", sm: "30%" } }}>
              <FormControlLabel
                sx={{ width: "10%" }}
                control={<Checkbox />}
                onChange={langCheack}
                name="otherWrite"
                label=""
              />
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              fullWidth
              sx={{
                background: "linear-gradient(to left, #3498db, #e74c3c)",
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                "&:hover": {
                  background: "linear-gradient(to right, #2980b9, #c0392b)", // Change the hover background color
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default Home;
