// import * as React from "react";
// import { useState } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import { Button, TableCell, TextField } from "@mui/material";
// import Box from "@mui/material/Box";
// import { Link } from "react-router-dom";
// import axios from "axios";

// export default function Admin() {
//   const [password, setPassword] = useState("");
//   const [userName, setUserName] = useState("");
//   const predefinedPassword = process.env.REACT_APP_PASSWORD;
//   const predefinedUsername = process.env.REACT_APP_USERNAME;

//   const handleDownload = (candidateEmail) => {
//     axios.get(`download/resume/${encodeURIComponent(candidateEmail)}`, {
//       responseType: "blob",
//     })
//     .then((response) => {
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", `${candidateEmail}-resume.pdf`);
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     })
//     .catch((error) => {
//       console.error("Error downloading resume:", error);
//       // Handle the error appropriately, e.g., show an error message to the user
//     });
//   };

//   const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
//   };
//   const handleUserChange = (e) => {
//     setUserName(e.target.value);
//   };
//   const [value, setValue] = React.useState("");

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (password === predefinedPassword && userName === predefinedUsername) {
//       setValue("submit");
//     } else {
//       // Display an error message or do something else on incorrect password.
//     }
//   };

//   const [data, setData] = useState();

// React.useEffect(() => {
//   try {
//     axios
//       .get("http://localhost:9090/viewForms")
//       .then((res) => setData(res.data));
//   } catch (error) {
//     console.log(error);
//   }
// }, []);

//   return (
//     <>
//       {value === "submit" ? (
//         <Box>
//           {/* <TextField
//             label="Search"
//             onChange={handleFilter}
//             sx={{ m: 1, width: { xs: "96%", sm: "20%" } }}
//             name=""
//             size="small"
//             type="name"
//           /> */}
//           <TableContainer component={Paper}>
//             <Table sx={{ minWidth: 650 }} aria-label="simple table">
//               <TableHead>
//                 <TableRow>
//                   <TableCell>Sr No.</TableCell>
//                   <TableCell>Name</TableCell>
//                   <TableCell>Email</TableCell>
//                   <TableCell>Name Of Institute</TableCell>
//                   <TableCell>Skill</TableCell>
//                   <TableCell>Experience</TableCell>
//                   <TableCell>Action</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {data.map((post, index) => {
//                   const {
//                     id,
//                     name,
//                     email,
//                     nameOfInstitute,
//                     itSkills,
//                     experience,
//                   } = post;
//                   return (
//                     <TableRow key={id}>
//                       <TableCell>{index + 1}</TableCell>
//                       <TableCell>{name}</TableCell>
//                       <TableCell>{email}</TableCell>
//                       <TableCell>{nameOfInstitute}</TableCell>
//                       <TableCell>{itSkills}</TableCell>
//                       <TableCell>
//                         {experience === null ? "Fresher" : experience}
//                       </TableCell>
//                       <TableCell>
//                         <button onClick={() => handleDownload(email)}>
//                           Download Resume
//                         </button>
//                       </TableCell>
//                       <TableCell>
//                         <Link>View</Link>
//                         {/* <Link to="/userDetails" style={{textDecoration: "none"}}>View</Link> */}
//                       </TableCell>
//                     </TableRow>
//                   );
//                 })}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           {/* <button onClick={generatePDF}>PDF</button> */}
//         </Box>
//       ) : (
//         <div>
//           <form onSubmit={handleSubmit}>
//             <Box
//               sx={{
//                 textAlign: "center",
//                 margin: "0 auto",
//                 boxShadow:
//                   "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
//                 width: "25%",
//                 padding: "20px 20px",
//                 borderRadius: "4px",
//               }}
//             >
//               <Box sx={{ margin: "15px 0" }}>
//                 <TextField
//                   type="text"
//                   value={userName}
//                   onChange={handleUserChange}
//                   placeholder="UserName"
//                   size="small"
//                 />
//               </Box>
//               <Box sx={{ margin: "15px 0" }}>
//                 <TextField
//                   type="password"
//                   placeholder="Enter Password"
//                   value={password}
//                   onChange={handlePasswordChange}
//                   size="small"
//                 />
//               </Box>
//               <Box sx={{ margin: "15px 0" }}>
//                 <button
//                   style={{
//                     background: "#1976d2",
//                     color: "#ffffff",
//                     borderRadius: "4px",
//                     padding: "11px 12px",
//                     border: "none",
//                     cursor: "pointer",
//                     textTransform: "uppercase",
//                     boxShadow:
//                       "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
//                   }}
//                   value={"submit"}
//                   type="submit"
//                 >
//                   Submit
//                 </button>
//               </Box>
//             </Box>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// // import React, { useState, useEffect } from "react";
// // import Table from "@mui/material/Table";
// // import TableBody from "@mui/material/TableBody";
// // import TableCell from "@mui/material/TableCell";
// // import TableContainer from "@mui/material/TableContainer";
// // import TableHead from "@mui/material/TableHead";
// // import TableRow from "@mui/material/TableRow";
// // import Paper from "@mui/material/Paper";
// // import TextField from "@mui/material/TextField";
// // import Box from "@mui/material/Box";
// // import { Link } from "react-router-dom";
// // import axios from "axios";
// // import TablePagination from "@mui/material/TablePagination";

// // export default function Admin() {
// //   const [password, setPassword] = useState("");
// //   const [userName, setUserName] = useState("");
// //   const predefinedPassword = process.env.REACT_APP_PASSWORD;
// //   const predefinedUsername = process.env.REACT_APP_USERNAME;

// //   const handlePasswordChange = (e) => {
// //     setPassword(e.target.value);
// //   };

// //   const handleUserChange = (e) => {
// //     setUserName(e.target.value);
// //   };

//   // const handleDownload = (candidateEmail) => {
//   //   axios.get(`download/resume/${encodeURIComponent(candidateEmail)}`, {
//   //     responseType: "blob",
//   //   })
//   //   .then((response) => {
//   //     const url = window.URL.createObjectURL(new Blob([response.data]));
//   //     const link = document.createElement("a");
//   //     link.href = url;
//   //     link.setAttribute("download", `${candidateEmail}-resume.pdf`);
//   //     document.body.appendChild(link);
//   //     link.click();
//   //     document.body.removeChild(link);
//   //   })
//   //   .catch((error) => {
//   //     console.error("Error downloading resume:", error);
//   //     // Handle the error appropriately, e.g., show an error message to the user
//   //   });
//   // };

// //   const [value, setValue] = useState("");

// //   const handleSubmit = (e) => {
// //     e.preventDefault();
// //     if (password === predefinedPassword && userName === predefinedUsername) {
// //       setValue("submit");
// //     } else {
// //       // Display an error message or do something else on incorrect password.
// //     }
// //   };
// //   const [page, setPage] = useState(0);
// //   const [rowsPerPage, setRowsPerPage] = useState(10);

// //   const handleChangePage = (event, newPage) => {
// //     setPage(newPage);
// //   };

// //   const handleChangeRowsPerPage = (event) => {
// //     setRowsPerPage(+event.target.value);
// //     setPage(0);
// //   };

// //   const [searchText, setSearchText] = useState("");
// //   const [filteredData, setFilteredData] = useState([]);
// //   const handleSearchChange = (event) => {
// //     const searchText = event.target.value;
// //     setSearchText(searchText);

// //     const filteredResult = data.filter((item) =>
// //       Object.values(item).some((value) =>
// //         String(value).toLowerCase().includes(searchText.toLowerCase())
// //       )
// //     );

// //     setFilteredData(filteredResult);
// //   };
// //   const [data, setData] = useState([]);

// //   useEffect(() => {
// //     axios.get("http://localhost:9090/viewForms")
// //       .then((res) => setData(res.data))
// //       .catch((error) => {
// //         console.log("Error fetching data:", error);
// //         // You can set an error state here if needed
// //       });
// //   }, []);

// //   return (
// //     <>
// //     <div
// //         style={{
// //           display: "flex",
// //           alignItems: "center",
// //           justifyContent: "space-between",
// //           marginBottom: "20px",
// //         }}
// //       >
// //         <input
// //           type="text"
// //           placeholder="Search..."
// //           value={searchText}
// //           onChange={handleSearchChange}
// //           style={{
// //             padding: "10px",
// //             border: "3px solid #ccc",
// //             borderRadius: "5px",
// //             fontSize: "16px",
// //             width: "300px",
// //             outline: "none",
// //             borderColor:"#2196F3",
// //             transition: "border-color 0.3s, box-shadow 0.3s",
// //           }}
// //           onFocus={(e) => {
// //             e.target.style.borderColor = "red";
// //             e.target.style.boxShadow = "0 0 5px rgba(33, 150, 243, 0.5)";
// //           }}
// //           onBlur={(e) => {
// //             e.target.style.borderColor = "#2196F3";
// //             e.target.style.boxShadow = "none";
// //           }}

// //         /> </div>
// //       {value === "submit" ? (
// //         <Box>
// //           <TableContainer component={Paper}>
// //           <Table sx={{ minWidth: 650,
// //       "& .MuiTableHead-root": {
// //         background: "linear-gradient(45deg, #800080 20%, #4CAF50 70%, #2196F3 30%, #800080 100%)", // Red to Yellow gradient for table header
// //         color: "white", // Set the text color for table header
// //       },
// //     }} aria-label="simple table">
// //               <TableHead>
// //                 <TableRow>
// //                   <TableCell style={{ color: "white" }}>Sr No.</TableCell>
// //                   <TableCell style={{ color: "white" }}>Name</TableCell>
// //                   <TableCell style={{ color: "white" }}>Email</TableCell>
// //                   <TableCell style={{ color: "white" }}>Name Of Institute</TableCell>
// //                   <TableCell style={{ color: "white" }}>Skill</TableCell>
// //                   <TableCell style={{ color: "white" }}>Experience</TableCell>
// //                   <TableCell style={{ color: "white" }}>Resume</TableCell>
// //                   <TableCell style={{ color: "white" }}>Action</TableCell>
// //                 </TableRow>
// //               </TableHead>
// //               <TableBody>
// //                 {filteredData
// //                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
// //                 .map((post , index) => {
// //                   const {
// //                     id,
// //                     name,
// //                     email,
// //                     nameOfInstitute,
// //                     itSkills,
// //                     experience,
// //                   } = post;
// //                   return (
// //                     <TableRow key={id} sx={
// //                       index % 2 === 0
// //                         ? { background: "#f2f2f2" } // Light gray background for even rows
// //                         : { background: "white" } // White background for odd rows
// //                     }>
// //                       <TableCell>{index + 1}</TableCell>
// //                       <TableCell>{name}</TableCell>
// //                       <TableCell>{email}</TableCell>
// //                       <TableCell>{nameOfInstitute}</TableCell>
// //                       <TableCell>{itSkills}</TableCell>
// //                       <TableCell>
// //                         {experience === null ? "Fresher" : experience}
// //                       </TableCell>
//                       // <TableCell>
//                       //   <button onClick={() => handleDownload(email)}>
//                       //     Download Resume
//                       //   </button>
//                       // </TableCell>
// //                       <TableCell>
// //                         <Link to={`/ViewForm/${id}`}>View</Link>
// //                       </TableCell>
// //                     </TableRow>
// //                   );
// //                 })}
// //               </TableBody>
// //             </Table>
// //             <TablePagination
// //             rowsPerPageOptions={[10, 25, 50]}
// //             component="div"
// //             count={filteredData.length}
// //             rowsPerPage={rowsPerPage}
// //             page={page}
// //             onPageChange={handleChangePage}
// //             onRowsPerPageChange={handleChangeRowsPerPage}
// //             sx={{
// //               background: "linear-gradient(45deg, #800080 20%, #4CAF50 70%, #2196F3 30%, #800080 100%)", // Red to Yellow gradient for background
// //               color: "white", // Set the text color
// //               "& .MuiPaginationItem-root": {
// //                 color: "white", // Set the color for page number buttons
// //               },
// //               "& .Mui-selected": {
// //                 background: "linear-gradient(45deg, #FF0000 80%, #FFFF00 10%)", // Yellow to Red gradient for the selected page
// //                 "&:hover": {
// //                   background: "linear-gradient(45deg, #FF0000 80%, #FFFF00 10%)", // Yellow to Red gradient for the selected page on hover
// //                 },
// //               },
// //             }}

// //           />
// //           </TableContainer>
// //           {/* <button onClick={generatePDF}>PDF</button> */}
// //         </Box>
// //       ) : (
// //         <div>
// //           <form onSubmit={handleSubmit}>
// //             <Box
// //               sx={{
// //                 textAlign: "center",
// //                 margin: "0 auto",
// //                 boxShadow:
// //                   "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
// //                 width: "25%",
// //                 padding: "20px 20px",
// //                 borderRadius: "4px",
// //               }}
// //             >
// //               <Box sx={{ margin: "15px 0" }}>
// //                 <TextField
// //                   type="text"
// //                   value={userName}
// //                   onChange={handleUserChange}
// //                   placeholder="UserName"
// //                   size="small"
// //                 />
// //               </Box>
// //               <Box sx={{ margin: "15px 0" }}>
// //                 <TextField
// //                   type="password"
// //                   placeholder="Enter Password"
// //                   value={password}
// //                   onChange={handlePasswordChange}
// //                   size="small"
// //                 />
// //               </Box>
// //               <Box sx={{ margin: "15px 0" }}>
// //                 <button
// //                   style={{
// //                     background: "#1976d2",
// //                     color: "#ffffff",
// //                     borderRadius: "4px",
// //                     padding: "11px 12px",
// //                     border: "none",
// //                     cursor: "pointer",
// //                     textTransform: "uppercase",
// //                     boxShadow:
// //                       "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
// //                   }}
// //                   value={"submit"}
// //                   type="submit"
// //                 >
// //                   Submit
// //                 </button>
// //               </Box>
// //             </Box>
// //           </form>
// //         </div>
// //       )}
// //     </>
// //   );
// // }

import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import axios from "axios";
import TablePagination from "@mui/material/TablePagination";
import LoginImg from "../assent/LoginImg.png";
import { Button, Typography } from "@mui/material";

export default function Admin() {
  const [data, setData] = useState([]);
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const predefinedPassword = process.env.REACT_APP_PASSWORD;
  const predefinedUsername = process.env.REACT_APP_USERNAME;
  const [authenticated, setAuthenticated] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUserChange = (e) => {
    setUserName(e.target.value);
  };

  const handleDownload = (candidateEmail) => {
    axios
      .get(`https://careers.ssbi.in/api/download/resume/${encodeURIComponent(candidateEmail)}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${candidateEmail}-resume.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading resume:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === predefinedPassword && userName === predefinedUsername) {
      setAuthenticated(true);
      sessionStorage.setItem("isLogin", "login");
    } else {
      setAuthenticated(false);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const handleSearchChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);

    const filteredResult = data.filter((item) =>
      Object.keys(item).some((key) =>
        String(item[key]).toLowerCase().includes(searchText)
      )
    );

    setFilteredData(filteredResult);
  };

  //

  useEffect(() => {
    axios
      .get("https://careers.ssbi.in/api/viewForms")
      .then((res) => {
        const fetchedData = res.data;
        setData(fetchedData);
        setFilteredData(fetchedData); // Initialize filtered data with all data
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      {sessionStorage.getItem("isLogin") === "login" ? (
        <Box>
          <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <TextField
          placeholder="Search..."
          value={searchText}
          sx={{ width: 350 }}
          onChange={handleSearchChange}
        />

        
      </div>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                "& .MuiTableHead-root": {
                  background:
                    "linear-gradient(45deg, #800080 20%, #4CAF50 70%, #2196F3 30%, #800080 100%)",
                  color: "white",
                },
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: "white" }}>Sr No.</TableCell>
                  <TableCell style={{ color: "white" }}>Name</TableCell>
                  <TableCell style={{ color: "white" }}>Email</TableCell>
                  <TableCell style={{ color: "white" }}>
                    Name Of Institute
                  </TableCell>
                  <TableCell style={{ color: "white" }}>Skill</TableCell>
                  <TableCell style={{ color: "white" }}>Experience</TableCell>
                  <TableCell style={{ color: "white" }}>Resume</TableCell>
                  <TableCell style={{ color: "white" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((candidate, index) => {
                    const {
                      id,
                      name,
                      email,
                      nameOfInstitute,
                      itSkills,
                      experience,
                    } = candidate;
                    return (
                      <TableRow
                        key={id}
                        sx={
                          index % 2 === 0
                            ? { background: "#f2f2f2" }
                            : { background: "white" }
                        }
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell>{nameOfInstitute}</TableCell>
                        <TableCell>{itSkills}</TableCell>
                        <TableCell>
                          {experience === null ? "Fresher" : experience}
                        </TableCell>
                        <TableCell>
                          <button
                            onClick={() => handleDownload(email)}
                            style={{
                              backgroundColor: "#4CAF50", // Background color
                              color: "white", // Text color
                              borderRadius: "4px", // Rounded corners
                              padding: "6px 10px", // Padding
                              border: "none", // Remove the border
                              cursor: "pointer", // Change cursor to pointer on hover
                              textTransform: "uppercase", // Uppercase text
                              boxShadow:
                                "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)", // Add a subtle box shadow
                              transition: "background-color 0.3s, color 0.3s", // Add transition effect
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = "blue"; // Change background color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = "#4CAF50"; // Restore original background color
                            }}
                          >
                            Download Resume
                          </button>
                        </TableCell>

                        <TableCell>
                          {/* <Link to={`/ViewForm/${id}`}>View</Link> */}
                          <Link
                            to={`/ViewForm/${id}`}
                            style={{
                              textDecoration: "none",
                              background: "#FF5722",
                              color: "white",
                              padding: "8px 16px",
                              borderRadius: "4px",
                              transition: "background 0.3s ease", // Add a smooth transition for the background color
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.background = "#E64A19"; // Change the background color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.background = "#FF5722"; // Restore the original background color
                            }}
                          >
                            View
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                background:
                  "linear-gradient(45deg, #800080 20%, #4CAF50 70%, #2196F3 30%, #800080 100%)",
                color: "white",
                "& .MuiPaginationItem-root": {
                  color: "white",
                },
                "& .Mui-selected": {
                  background:
                    "linear-gradient(45deg, #FF0000 80%, #FFFF00 10%)",
                  "&:hover": {
                    background:
                      "linear-gradient(45deg, #FF0000 80%, #FFFF00 10%)",
                  },
                },
              }}
            />
          </TableContainer>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <Box
            display="flex"
            sx={{
              background:
                "linear-gradient(60deg, rgba(71,250,86,0.8939950980392157) 0%, rgba(0,224,255,1) 100%)",
              width: "800px",
              borderRadius: 4,
            }}
            // justifyContent="space-around"
          >
            <Box width="300px" sx={{ display: { xs: "node", md: "block" } }}>
              <img src={LoginImg} alt="LoginImg" />
            </Box>
            <Box
              width="350px"
              sx={{
                background: "#ffeeffbb",
                my: "100px",
                ml: "100px",
                p: 5,
                borderRadius: 3,
              }}
            >
              <Typography
                variant="h4"
                marginTop="10px"
                align="center"
                sx={{ fontWeight: 600 }}
              >
                Job Application
              </Typography>
              <Typography variant="h6" align="center">
                SSB Digital
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box sx={{ margin: "15px 0" }}>
                  <Typography>Username</Typography>
                  <TextField
                    type="text"
                    value={userName}
                    sx={{ width: "270px" }}
                    onChange={handleUserChange}
                    placeholder="UserName"
                    size="small"
                  />
                </Box>
                <Box sx={{ margin: "15px 0" }}>
                  <Typography>Password</Typography>
                  <TextField
                    type="password"
                    placeholder="Enter Password"
                    value={password}
                    sx={{ width: "270px" }}
                    onChange={handlePasswordChange}
                    size="small"
                  />
                </Box>
                <Box sx={{ margin: "25px 0" }}>
                  <Button
                    variant="contained"
                    sx={{ width: "270px" }}
                    value={"submit"}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
